import axios from "axios";

const ApiBaseURL =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:4000/api";

const instance = axios.create();
instance.defaults.baseURL = ApiBaseURL;
instance.defaults.timeout = 15000;

export default instance;
